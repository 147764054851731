import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import styles from './admin.module.scss';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import images from '../../assets/images/images';
import { APIConstant } from '../../services/APIConstant';
import { Dialog } from '@mui/material';
import CreateNewTextEditor from '../../components/texteditor/createnew';
import HeaderMenu from '../../components/admin/header/menu/menu';
import { allPagesList } from '../../store/slices/pagesSlice';
import { useSelector } from 'react-redux';

const AdminHome = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pages, setPages] = useState([]);
    const [homePages, setHomePages] = useState([]);
    const [marketingPages, setMarketingPages] = useState([]);
    const [policyPages, setPolicyPages] = useState([]);
    const [otherPages, setOtherPages] = useState([]);
    const [draftPages, setDraftPages] = useState([]);
    const dispatch = useDispatch();

    const pagesList = useSelector((state) => state.allPagesList);
    
    // console.log('PageList', pagesList)

    useEffect(() => {
        if(!sessionStorage.getItem("accessid")){
            navigate('/login')
        }
    }, [])

   
    const handleClose = () => {
        setOpen(false);
    };
  
    
    useEffect(() => {
        fetch(APIConstant.getPageList)
            .then(res => res.json())
            .then((data) => {
                // console.log(data)
                setPages(data)
                dispatch(allPagesList(data))
            })
            .catch(err => console.log(err))

            
        }, [])
    // }, [pagesList])
    


    useEffect(() => {
        // console.log('pagesLists :', pages)
        setHomePages(pages?.filter(item => item.page_category === 'home' && item.status === 'published' && item.visibility === 'active'))
        setMarketingPages(pages?.filter(item => item.page_category === 'marketing' && item.status === 'published' && item.visibility === 'active'))
        setPolicyPages(pages?.filter(item => item.page_category === 'policy' && item.status === 'published' && item.visibility === 'active'))
        setOtherPages(pages?.filter(item => item.page_category === 'others' && item.status === 'published' && item.visibility === 'active'))
        setDraftPages(pages?.filter(item => item.status === 'draft' && item.visibility === 'active'))
    }, [pages])

    const getLandingPageData = () => {
        fetch(APIConstant.getLandingPage)
        .then(res => res.json())
        .then((data) => {
            navigate(`/admin/preview?id=${data[0].page_id}`)
        })
        .catch(err => console.log(err))
    }

    const moveToPage = (pageid) => {
      
     
        navigate(`/admin/preview?id=${pageid}`)
    }





    // 1 hour Idel Time Session Out
    let idleTime = 0;
    let continuousWorkingTime = 0;

    // 1 hour in milliseconds
    const maxIdleTime = 60 * 60 * 1000; 
    const maxContinuousWorkingTime = 3 * 60 * 60 * 1000; 
    
    // Reset idle time on activity
    const resetIdleTime = () => {
        idleTime = 0;
    };

    // Increment idle time
    const incrementIdleTime = () => {
        idleTime += 1000;
        // Check if the idle time exceeds the max allowed idle time (1 hour)
        if (idleTime >= maxIdleTime) {
            // Perform session timeout actions (e.g., log the user out)
            alert("Your session has expired due to inactivity.");
            sessionStorage.removeItem('accessid');
            navigate('/login')
            window.location.href = "/login"; // Or any other logic for session timeout
        }
    };

    // Increment Continuous working Time
    const incrementContinuousWorkingTime = () => {
        continuousWorkingTime += 1000;
        // Check if the Continuous working time exceeds the max allowed Continuous working time (3 hour)
        if (continuousWorkingTime >= maxContinuousWorkingTime) {
            // Perform session timeout actions (e.g., log the user out)
            alert("Your session has expired due to maximum time exceeds.");

            sessionStorage.removeItem('accessid');
            navigate('/login')
            window.location.href = "/login"; // Or any other logic for session timeout
        }
    }

    // Monitor user activity
    useEffect(() => {
        // Reset idle time on mousemove, keypress, or other activity
        document.addEventListener("mousemove", resetIdleTime);
        document.addEventListener("keypress", resetIdleTime);
        document.addEventListener("click", resetIdleTime); // Optional: add other event listeners as needed

        // Periodically check for inactivity
        setInterval(incrementIdleTime, 1000); // Check every second

        setInterval(incrementContinuousWorkingTime, 1000); // Check every second
    }, [])
    
    return (
        <div className={styles.adminHome}>
            <header className={styles.header}>
                <div></div>
                <div className={styles.profile}>
                    <HeaderMenu />
                </div>
            </header>
            <aside className={styles.sidebar}>
                <div className={styles.logoCont}>
                    <img src={images.LOGO} alt="Logo" height="40" />
                </div>
                <ul>
                    <li>
                        <header>
                            <h5>HOME PAGES <br /><span></span></h5>
                            {/* <NavLink to="/admin/preview" className={({ isActive, isPending }) =>
                                isPending ? styles.pending : isActive ? styles.active : ""
                            }>+</NavLink> */}
                        </header>
                        <ul>
                            
                            <li><a onClick={getLandingPageData}>Global Default Page</a></li>
                            {homePages.map((item,i) => {
                                return(
                                    <>
                                    {item.is_landing === 'no' && 
                                        <li key={i}><NavLink to={`/admin/preview?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                                    }
                                    </>
                                )}
                            )}
                           
                        </ul>
                    </li>
                    <li>
                        <header>
                            <h5>MARKETING PAGES <br /></h5>
                            {/* <NavLink to="/admin/preview" className={({ isActive, isPending }) =>
                                isPending ? styles.pending : isActive ? styles.active : ""
                            }>+</NavLink> */}
                        </header>
                        <ul>
                            {marketingPages.map((item,i) => {
                                return(
                                    // <li key={i}><NavLink to={`/admin/preview?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                                    <li key={i}><a onClick={() => moveToPage(item.page_id)}>{item.page_name}</a></li>
                                )}
                            )}
                        </ul>
                    </li>
                    <li>
                        <header>
                            <h5>POLICY PAGES <br /></h5>
                            {/* <NavLink to="/admin/preview" className={({ isActive, isPending }) =>
                                isPending ? styles.pending : isActive ? styles.active : ""
                            }>+</NavLink> */}
                        </header>
                        <ul>
                            {policyPages.map((item,i) => {
                                return(
                                    // <li key={i}><NavLink to={`/admin/preview?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                                    <li key={i}><a onClick={() => moveToPage(item.page_id)}>{item.page_name}</a></li>
                                )}
                            )}
                        </ul>
                    </li>
                    <li>
                        <header>
                            <h5>OTHER PAGES <br /></h5>
                            {/* <NavLink to="/admin/preview" className={({ isActive, isPending }) =>
                                isPending ? styles.pending : isActive ? styles.active : ""
                            }>+</NavLink> */}
                        </header>
                        <ul>
                            {otherPages.map((item,i) => {
                                return(
                                    <li key={i}><NavLink to={`/admin/preview?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                                )}
                            )}
                        </ul>
                    </li>
                    <li>
                        <header>
                            <h5>DRAFT PAGES </h5>
                            <NavLink to="/admin/preview">ADD PAGE</NavLink>
                        </header>
                        
                        <ul>
                            {draftPages.map((item,i) => {
                                return(
                                    <li key={i}><NavLink to={`/admin/preview?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                                )}
                            )}
                        </ul>
                    </li>
                    <li className={styles.pt20}><NavLink to="/admin/organization" className={({ isActive, isPending }) =>
                        isPending ? styles.pending : isActive ? styles.active : ""
                    }>Org Settings</NavLink></li>
                </ul>
            </aside>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <CreateNewTextEditor />
            </Dialog>
            <section className={styles.adminWrapper}>
                <Outlet />
            </section>
        </div>
    )
}

export default AdminHome
