import React, { useEffect, useState } from 'react'
import styles from './pageNotFound.module.scss';
const PageNotFound = () => {
  return (
    <div className={styles.pageNotFound}>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  )
}
export default PageNotFound