import React, { useEffect, useRef, useState } from 'react'
import styles from './footer.module.scss'
import images from '../../../assets/images/images'
import axios from 'axios'
import { APIConstant } from '../../../services/APIConstant'
import { NavLink, useNavigate } from 'react-router-dom'
// import { countryContext } from '../../../App'
import { useContext } from 'react'
import PolicyList from '../../../pages/policy/policyList'
import CompanyList from '../../../pages/company/companyList'
import { Field, Form, Formik } from 'formik'
import { useLocation } from 'react-router-dom';

function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Enter your email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

const Footer = () => {
    const [legalLists, setLegalLists] = useState();
    const [showContactUsForm, setShowContactUsForm] = useState(true);
    const linkedinRef = useRef();
    const navigate = useNavigate();
    let location = useLocation();
    // const country = useContext(countryContext)


    useEffect(() => {
        if(location.pathname === '/contactus'){
            setShowContactUsForm(false)
        }
        
      }, []);


    useEffect(() => {
        axios.get(APIConstant.getLegalList)
        .then(res => {  
           setLegalLists(res.data)
        })
        .catch(err => console.log(err))
      }, [])

      const openLegalPage = (id) => {
        console.log(id)
      }

    //   console.log('country :', )

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://platform.linkedin.com/in.js";
        script.type = "text/javascript"
        script.innerHTML = 'lang: en_US'
        
        script.async = true;

        const script2 = document.createElement('script');
      
        script2.type = "IN/FollowCompany";
        script2['data-id'] = "pay-orc"
        script2['data-counter'] = "bottom"
      
        linkedinRef.current?.appendChild(script);
        linkedinRef.current?.appendChild(script2);
      
        return () => {
            linkedinRef.current?.removeChild(script);
            linkedinRef.current?.removeChild(script2);
        }
      }, []);
   

  return (
    <div className={styles.footer_container}>
        <div className='container'>
            <div className={styles.footer_txt_cont}>
                
                <div className={styles.slinks}>
                    <img src={images.LOGO} alt="Logo" height="60"/>
                    <div ref={linkedinRef}>

                    </div>
                   
                </div>
                <div className={styles.links}>
                    
                    <div className={styles.flink_cont}>
                        <h4>Legal</h4>
                        <ul>
                            <PolicyList />
                        </ul>
                    </div>
                    <div className={styles.flink_cont}>
                        <h4>Company</h4>
                        <ul>
                            {/* <li><a>About us</a></li> */}
                            {/* <li><a>Careers</a></li>
                            <li><a>Case Studies</a></li>
                            <li><a>Blog</a></li>
                            <li><a>Contact</a></li> */}
                            <CompanyList />
                        </ul>
                    </div>
                    <div className={styles.flink_cont}>
                        <h4>Developers</h4>
                        <ul>
                            <li><a href="http://api.payorc.com/" target="_blank">API</a></li>
                            <li><a href="http://api.payorc.com/" target="_blank">Guides</a></li>
                        </ul>
                    </div>
                    <div className={styles.flink_cont}>
                        <h4>Address</h4>
                        <p>Orc Technologies FZE <br/>AT-G01, Atrium Tower <br/>UAQFTZ, Umm Al Quwain<br/>United Arab Emirates</p>
                        <a>Sales@PayOrc.com</a>
                    </div>
                    {showContactUsForm &&
                        <div className={`${styles.flink_cont} ${styles.formLink_cont}`}>
                            <h4>Contact Us</h4>
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={async (values) => {
                                    navigate('/company/contact', {state : values})
                                }}
                                >
                                {({ errors, touched, isValidating }) => (
                                    <Form>
                                        <Field
                                        id="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        type="email"
                                        validate={validateEmail}
                                        />
                                        {errors.email && touched.email && <div className='error'>{errors.email}</div>}
                                        <button type="submit" >Write a mail</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    }
                </div>
                <div className={styles.copyright}>
                    <div className={styles.cpyL}>
                        <p><span className={styles.copyRight}>Copyrights <img src={images.COPYRIGHT} alt="Copyright" width="14"/> 2024, Orc Technologies.</span> <span className={styles.reserved}>All Rights Reserved.</span></p>
                    </div>
                    <div className={styles.cpyC}>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/pay-orc/" target="_blank"><img src={images.LINKEDIN} alt="Linkedin" width="30"/></a></li>
                        </ul>
                    </div>
                    <div className={`${styles.cpyR}`}>
                         Bug Boundry Security Compliance 
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer