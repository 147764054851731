import React, { useEffect, useState } from 'react'
import styles from './dynamicPage.module.scss';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import Navbar from '../../home/navbar/navbar';
import Footer from '../../home/footer/footer';
import { Helmet } from 'react-helmet';

const DynamicPageRenderer = ({pageid}) => {
    const [pageId, setPageId] = useState()
    const [pageData, setPageData] = useState({});
    const [pageSEOData, setPageSEOData] = useState({});

  useEffect(() => {
    axios.post(APIConstant.getSelectedTemplate, { page_id: pageid})
    .then(res => {
        setPageId(res.data[0])
    })
    .catch(err => console.log(err))
}, [])
useEffect(() => {

    axios.post(APIConstant.getSelectedPage, {page_id: pageid})
    .then(res => {
        setPageData(res.data[0])
    })
    .catch(err => console.log(err))
    console.log('Here Working')
    axios.post(APIConstant.getSelectedSEO, {page_id: pageid})
    .then(res => {
        setPageSEOData(res.data[0])
    })
    .catch(err => console.log(err))
    console.log('Here also Working')

}, [pageid])

  return (

    <div className={`${styles.dynamicPageCont}`}>
        
       {console.log('pageSEOData', pageSEOData)}

       <Helmet>
        
        <title>{pageSEOData.title}</title>
        <meta name="description" content={pageSEOData.description}/>
        <meta name="keywords" content={pageSEOData.keywords}/>
        <link rel="canonical" href="https://payorc.com"/>
       </Helmet>

       <div className={styles.mobileHeader} style={{backgroundColor: '#398378', height: '70px'}}>
            <Navbar />
        </div>
        <div className={styles.pageContainer}>
            {/* <h4>{pageData?.page_name}</h4> */}
            <div className={styles.heading}>
                <h4>{pageData?.page_name}</h4>
            </div>
            <div className={styles.contentWrapper}>
                <div dangerouslySetInnerHTML={ {__html: pageId?.text_content} } />
            </div>
        </div>
        <Footer />

    </div>
  )
}

export default DynamicPageRenderer